import { ref } from 'vue'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { GetResourceCategory } from '@/api/resourceCategory'
import { usePermissions } from '@/use/permissions'

export const useResourceCategory = () => {
  const { shopId } = useShop()
  const { fetchAll } = useFetch()
  const { checkAction } = usePermissions()
  const resourceCategoryList = ref([])
  const getAllResourceCategory = async () => {
    if (!checkAction('admin.resourceItemCategory.page')) return
    await fetchAll(
      GetResourceCategory,
      { shopId: shopId.value, start: 0, limit: 100 },
      (res) => { resourceCategoryList.value = res },
      (err) => { throw new Error(err) },
    )
  }
  return { getAllResourceCategory, resourceCategoryList }
}
